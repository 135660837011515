import { v } from './vars';

const isActiveStorage = (path) => !!(/^cms-images\/[a-zA-Z0-9]{28}$/.test(path));
export const pathJoin = (a, b) => `${a.replace(/\/+$/, '')}/${b.replace(/^\/+/, '')}`.replace(/(.)\/$/, '$1');

export const assetPath = (path) => (path ? pathJoin(v('assetsHost'), path) : '');
export const defaultAssetPath = (path) => (path ? pathJoin(v('defaultAssetsHost'), path) : '');

export const cmsAssetPath = (path) => {
  if (!path) return '';
  if (isActiveStorage(path)) return pathJoin(v('defaultCmsAssetsHost'), path);
  return `${v('cmsAssetsHost')}${path}`.replace(/'/g, '%27');
};

export const defaultCmsAssetPath = (path) => (path ? pathJoin(v('defaultCmsAssetsHost'), path) : '');
